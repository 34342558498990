import React, { Component } from "react";
import { Container, Card, CardHeader, CardBody, Button } from "reactstrap";
import AppContext from "../../AppContext";
import axios from "axios";
const DEFAULT_QUERY_Logout = "AdminMain/LogOut";

class SessionClose extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;

  }

  componentDidMount() {
    this.logout();
  }

  static contextType = AppContext;

  async logout() {
    axios
      .all([
        axios.get(
          this.context.API_URL_HOST +
          DEFAULT_QUERY_Logout +
          "?" +
          this.context.encrypt(this.context.API_TOKEN)
        ),
      ])
      .then(
        axios.spread((LogoutMsg) => {
          if (
            LogoutMsg.data.errorMessage != 0) {
            if (this.props.displayError) this.props.displayError(
              LogoutMsg.data.msgError,
              LogoutMsg.data.errorMessage
            );
          }

        })
      )
      .catch((error) => {
        if (error.response?.status == 405) {
          this.SessionCloseRedirect();
        }
        if (this.props.displayError)
          this.props.displayError(0, "", error);
      });
  }

  SessionCloseRedirect() {
    window.location.replace(this.context.login_url + "action=sessionclose");
  }

  render() {
    return (
      <div className="app-body">
        <main className="main">
          <Container>
            <center>
              <h3
                className="loading-ms"
                style={{ marginTop: "50px", marginBottom: "20px" }}
              >
                Thank you for using Deferredcomp.morganstanleyatwork.com.
                <br />
                <br />
                Your session has ended. Please close your browser.
                <br />
                <br />
              </h3>
              {/* <Button
                a-id={"btn_SessionClose_BackHome"}
                className="btn btn-secondary ms-logon-btn btn-save-ms"
                onClick={() => this.props.backHome()}
              >
                Back Home
              </Button> */}
              <br />
              <br />
            </center>
          </Container>
        </main>
      </div>
    );
  }
}
export default SessionClose;
