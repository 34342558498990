const ApiUrl = {
  API_URL_HOST:  process.env.NODE_ENV === "development"? "http://localhost:59099/" : "http://localhost:8081/rest/",
  REACT_URL_HOST: "http://localhost:8081/",
  REACT_URL_LOGIN: "http://localhost:3000/",
};

let PageName = "";
let app_env = "dev";
let sal_urls = {
  "dev": "http://nqdc-deferralhandler-api.webfarm-dev.ms.com/deferral_handler/rest/",
  "qa": "http://nqdc-deferralhandler-api.webfarm-qa.ms.com/deferral_handler/rest/",
  "prod": "http://nqdc-deferralhandler-api.webfarm.ms.com/deferral_handler/rest/"
};
let sal_urls_internals = {
  "dev": "http://nqdc-deferralhandler-api-internal.webfarm-dev.ms.com/deferral_handler/rest/",
  "qa": "http://nqdc-deferralhandler-api-internal.webfarm-qa.ms.com/deferral_handler/rest/",
  "prod": "http://nqdc-deferralhandler-api-internal.webfarm.ms.com/deferral_handler/rest/"
};
let react_login_urls = {
  "dev": "https://login-dev-nqdc.morganstanleyatwork.com",
  "qa": "https://login-qa-nqdc.morganstanleyatwork.com",
  "qa1": "https://login-qa1-nqdc.morganstanleyatwork.com",
  "preprod": "https://login-preprod-deferredcomp.morganstanleyatwork.com",
  "prod": "https://login-deferredcomp.morganstanleyatwork.com"
};

export const processEnvironment = () => {
  const PageUrl = window.location.href;
  if (process.env.NODE_ENV === "production") {
    ApiUrl.CLASSIC_URL_HOST = "/";
    const intPos = PageUrl.indexOf("//");
    if (intPos != -1) {
      const intPos2 = PageUrl.substr(intPos + 2).search(/[\.:\/]/);
      if (intPos2 != -1) {
        PageName = PageUrl.substr(intPos + 2, intPos2).toLowerCase();
        if (
          PageName == "localhost" &&
          PageUrl.substr(intPos + 2 + intPos2, 1) == ":"
        ) {
          ApiUrl.API_URL_HOST = "https://localhost:8081/rest";
          ApiUrl.REACT_URL_HOST = "http://localhost:8081/";
          ApiUrl.API_URL_HOST =  process.env.NODE_ENV === "development"? "http://localhost:59099/" : "http://localhost:8000/";
        } else if (PageName == "www2") {
          ApiUrl.API_URL_HOST = "https://adminapi2.deferral.com/";
        } else if (PageName == "www") {
          ApiUrl.API_URL_HOST = "https://adminapi.deferral.com/";
        } else {
          app_env = getAppEnvironment(window.location.hostname);
          let isInternal = window.location.hostname.match(/(internal)/);
          //ApiUrl.API_URL_HOST = "https://" + PageName + "api.deferral.com/";
          if (isInternal) {
            ApiUrl.API_URL_HOST = sal_urls_internals[app_env];
          } else {
            //TODO change to origin
            ApiUrl.API_URL_HOST = window.location.origin + "/rest/";
            ApiUrl.REACT_URL_LOGIN = react_login_urls[app_env];
          }
        }
      }
    }
  }
};

processEnvironment();

export const config = ApiUrl;
export const algorithm = "aes-256-cbc";
export const cypherkey = "58945803628475210256298743257410";
export const cypheriv = "2578018473365974";

// THESE ARE PROVISIONAL CONSTANTS IN HERE!
export const NewRecord = -28888;
export const NullRecord = 0; //MUST BE 0 SO THE PARTICIPANT SIDE WORKS

export function getAppEnvironment(hostname) {
  let match = hostname.match(/((dev|qa|qa1))?-nqdc.morganstanleyatwork.com/);
  let matchForProd = hostname.match(/((preprod))?-deferredcomp.morganstanleyatwork.com/);
  let app_env = "prod";
  const matchedVal = (match && match[2]) || (matchForProd && matchForProd[2]);
  if (matchedVal) {
    switch (matchedVal) {
      case 'preprod': app_env = "preprod"; break;
      case 'qa1': app_env = "qa1"; break;
      case 'qa': app_env = "qa"; break;
      case 'dev': app_env = "dev"; break;
      default: app_env = "prod"; break;
    }
  }
  return app_env;
}