import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class MS_Header extends Component {
  constructor(props) {
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this);
  }
  handleRedirect() {
    if (
      this.props.userData &&
      this.props.userData.groupID &&
      this.props.userData.groupID !== 1
    ) {
      window.location.href =
        "https://www.morganstanley.com/atwork/deferred-compensation-plans?icid=mnsltwk-tl-sunrise-6095";
    }
  }

  render() {
    let imageEnv;
    let className;
    var PageName;
    if (top.location.hostname.indexOf(".") != -1) {
      PageName = top.location.hostname
        .substring(0, top.location.hostname.indexOf("."))
        .toLowerCase();
    } else {
      PageName = top.location.hostname.toLowerCase();
    }
    if (PageName == "localhost") {
      imageEnv = <img className="ms-header-sit-logo" alt="" />;
    } else if (PageName == "dev-nqdc") {
      imageEnv = <img className="ms-header-sit-logo" alt="" />;
    } else if (PageName == "uat") {
      imageEnv = <img className="ms-header-uat-logo" alt="" />;
    } else if (PageName == "qa-nqdc") {
      imageEnv = <img className="ms-header-qa-logo" alt="" />;
    }
    let leftHeader1 = <img className="ms-header-logo" alt="" hidden="true" />;
    if (this.props.clientID != 0 && this.props.clientID != 57 /*Mercer*/) {
      leftHeader1 = (
        <a id="logo-lg" onClick={this.handleRedirect}>
          <img className="ms-header-logo" alt="" />
        </a>
      );
    }
    return (
      <header className="joy0 joy6 app-header navbar justify-content-start">
        {imageEnv}
        {leftHeader1}
      </header>
    );
  }
}

export default MS_Header;
