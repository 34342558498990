import React from 'react';
import SessionClose from "./containers/Full/SessionClose";
import MS_Header from "./components/Header/MS_Header";
import MS_Footer from "./components/Footer/MS_Footer";
const DEFAULT_QUERY_Logout = "AdminMain/LogOut";
import AppContext from "./AppContext";
import axios from "axios";
import { Card, Container } from "reactstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
    this.onBackHome = this.onBackHome.bind(this);
  }
  static contextType = AppContext;

  async onBackHome() {
    axios
      .all([
        axios.get(
          this.context.API_URL_HOST +
          DEFAULT_QUERY_Logout +
          "?" +
          this.context.encrypt(this.context.API_TOKEN)
        ),
      ])
      .then(
        axios.spread((LogoutMsg) => {
          if (LogoutMsg.data.errorMessage != 0) {
          } else {
            window.location.replace(this.context.login_url);
          }
        })
      )
      .catch((error) => {
        window.location.replace(this.context.login_url);
      });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.subMenuIndex !== nextProps.subMenuIndex && this.state.error) {
      this.setState({ hasError: false });
    }
    return true;
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, 'error');
    this.setState({ error: error?.toString() });
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }


  componentDidMount() {
    this.ShowActualError = ["dev-nqdc", "qa-nqdc", "localhost"].includes(top?.location?.hostname?.split('.')?.[0]?.toLowerCase());
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.rootError) {
        return <div className="app">
          <MS_Header
          />
          <div className="app-body">
            <main className="main">
              <Container>
                <Card className="card-bg-AdminCard-main text-center">
                  {this.ShowActualError ? <div className="docMessageError mt20">{this.state.error}</div> : <b className='mt20 docMessageError'>An error has occurred, please try again later.</b>}
                </Card>
              </Container>
            </main>
          </div>
          <MS_Footer displayError={this.displayError} />
        </div>;
      }
      return <Card className="card-bg-AdminCard-main text-center">
        {this.ShowActualError ? <div className="docMessageError mt20">{this.state.error}</div> : <b className='mt20 docMessageError'>An error has occurred, please try again later.</b>}
      </Card>;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;