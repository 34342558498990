import React, { useEffect } from "react";
import AppContext from "./AppContext";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "regenerator-runtime/runtime";
import "./web.config";
import { config, cypherkey, cypheriv, algorithm } from "./constants/api_host";
import axios from "axios";

//STEP 1:
//create components using React.lazy
const StandardTheme = React.lazy(() =>
  import("./containers/Full/LayoutStandard.js")
);
const MSTheme = React.lazy(() => import("./containers/Full/LayoutMS.js"));

// Styles
// Import Flag Icons Set
//import "flag-icon-css/css/flag-icon.min.css";

// Import Font Awesome Icons Set
//import 'font-awesome/css/font-awesome.min.css';

// Import Simple Line Icons Set
//import 'simple-line-icons/css/simple-line-icons.css';

// Temp fix for reactstrap
import "./scss/core/_dropdown-menu-right.scss";
// Temp fix for react-datepicker
import "react-datepicker/dist/react-datepicker.min.css";
import queryString from "query-string";

// Import Main styles for this application
//import "../scss/style.scss";
//import "../scss/client67.style.scss";

// Containers
import Full from "./containers/Full/";
import ErrorBoundary from "./ErrorBoundary";

// Views
// import Login from "./views/Pages/Login/";
// import Register from "./views/Pages/Register/";
// import Page404 from "./views/Pages/Page404/";
import Page500 from "./views/Pages/Page500/";

const DEFAULT_QUERY_REPORT_DOWNLOAD_BY_FILEID = "AdminReports/DownloadReport";
const DEFAULT_QUERY_COOKIE_TOKEN = "handler/token";
const DEFAULT_QUERY_POLL_REPORT_STATUS = "AdminGenerateReports/PollReportStatus";
const DEFAULT_QUERY_GENERATE_FILEID = "AdminGenerateReports/GenerateFileId";

//Decrypt Function
var CryptoJS = require("crypto-js");
let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

function setLocalZone(date) {
  var ref = date.toString().substring(0, 10);
  var ret = null;
  if (ref.search("-") != -1) {
    ret = new Date(
      ref.substring(5, 7) +
      "/" +
      ref.substring(8, 10) +
      "/" +
      ref.substring(0, 4)
    );
  } else if (ref.search("/") != -1) {
    ret = new Date(date);
  } else {
    ret = new Date(
      (date.getMonth() + 1).toString() +
      "/" +
      date.getDate().toString() +
      "/" +
      date.getFullYear().toString()
    );
  }
  return ret;
}

function encrypt(text) {
  // if (process.env.NODE_ENV == "production") {
  //if (1 == 1) {
  var key = CryptoJS.enc.Utf8.parse(cypherkey);
  var iv = CryptoJS.enc.Utf8.parse(cypheriv);
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  let b64 = CryptoJS.enc.Base64.parse(encrypted.toString());
  encrypted = b64.toString(CryptoJS.enc.Hex);
  return encrypted;
  // }
  // else {
  //   return text;
  // }
}

function decrypt(text) {
  // if (process.env.NODE_ENV == "production") {
  //if (1 == 1) {
  var key = CryptoJS.enc.Utf8.parse(cypherkey);
  var iv = CryptoJS.enc.Utf8.parse(cypheriv);
  var textBytes = {
    ciphertext: CryptoJS.enc.Hex.parse(text),
  };
  var bytes = CryptoJS.AES.decrypt(textBytes, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  var decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
  // } 
  // else {
  //   return text;
  // }
}

var reportProcess = false;

function retryReportCall(fileId, pageObj) {
  if (!reportProcess) {
    return;
  }
  const params = new URLSearchParams({
    fileId
  });

  axios
    .get(pageObj.context.API_URL_HOST +
      DEFAULT_QUERY_POLL_REPORT_STATUS +
      "?" +
      encrypt((params ?? "") + pageObj.context.API_TOKEN),
      axiosConfig).then((response) => {
        if (response.data?.data?.retry) {
          fileId = response.data.data.fileId;
          setTimeout(() => retryReportCall(fileId, pageObj), 5000);
        } else {
          if (response.data?.data?.fileName.includes('Error')) {
            pageObj.props.displayError(
              '',
              "Report generation timed out"
            );
          } else {
            let url = "";
            if (fileId != null && fileId > 0) {
              url =
                userSettings.API_URL_HOST +
                DEFAULT_QUERY_REPORT_DOWNLOAD_BY_FILEID +
                "?" +
                encrypt("fileId=" + fileId + userSettings.API_TOKEN);
              let URLOpt =
                "toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=800,height=600";
              window.open(url, "_blank", URLOpt);
            }
          }
          reportProcess = false;
          pageObj.setState({ isLoading: false });
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          setTimeout(() => retryReportCall(fileId), 5000);
        } else {
          reportProcess = false;
          pageObj.props.displayError(0, "", "Report generation timed out");
          pageObj.setState({ isLoading: false });
        }
      });
}

function cancelReport() {
  reportProcess = false;
}

async function generateFileId(pageObj) {
  try {
    const response = await axios.get(
      pageObj.context.API_URL_HOST +
      DEFAULT_QUERY_GENERATE_FILEID +
      "?" + pageObj.context.encrypt(pageObj.context.API_TOKEN),
      axiosConfig
    );
    if (response?.data?.errorMessage && response?.data?.errorMessage !== 0) {
      pageObj.props.displayError(
        response.data.msgError,
        response.data.errorMessage
      );
      return null;
    } else {
      return response.data?.data;
    }
  } catch (error) {
    pageObj.props.displayError(0, "", error);
    return null;
  }
}

// *** BEGIN : Functions to get report conversion status and download report
async function downloadReport(pageObj, url, reportData) {
  if (reportProcess == true) {
    console.log("report on process.");
    return;
  }
  reportProcess = true;
  pageObj.setState({ isLoading: true });
  let bodyFormData = new FormData();
  let fileId = await generateFileId(pageObj);
  if (!fileId) {
    pageObj.setState({ isLoading: false });
    return;
  }
  reportData.fileId = fileId;
  bodyFormData.append("values", JSON.stringify(reportData));
  axios
    .postForm(url, bodyFormData, axiosConfig)
    .then((response) => {
      if (response.data?.data?.isReportEmpty) {
        pageObj.props.displayError("Report is empty, nothing to print.", 10001);
        reportProcess = false;
        pageObj.setState({ isLoading: false });
        return;
      }
      if (response.data?.errorMessage && response.data.errorMessage != 0) {
        pageObj.props.displayError(
          response.data.msgError,
          response.data.errorMessage
        );
      } else if (response.data?.data?.reportResponse != "") {
        // pageObj.props.displayError(0, response.data?.data?.reportResponse);
        pageObj.props.displayError(
          response.data.msgError,
          response.data.errorMessage
        );
      } else {
        let fileName = response.data.data.fileName;
        let fileContent = response.data.data.content;
        fileId = response.data.data.fileId;
        let url = "";
        if (fileId != null && fileId > 0) {
          url =
            userSettings.API_URL_HOST +
            DEFAULT_QUERY_REPORT_DOWNLOAD_BY_FILEID +
            "?" +
            encrypt("fileId=" + fileId + userSettings.API_TOKEN);
          let URLOpt =
            "toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=800,height=600";
          window.open(url, "_blank", URLOpt);
          // } else if (fileContent == null) {
          //   const baseUrl = window.location.origin;
          //   url = baseUrl + "/PDFReports/" + fileName;
          //   if (url.indexOf("localhost") != -1) {
          //     url = url.replace(":8081", "");
          //   }
          //   let URLOpt =
          //     "toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=800,height=600";
          //   let w = window.open(url, "_blank", URLOpt);
        } else {
          url = window.URL.createObjectURL(new Blob([atob(fileContent)]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
      reportProcess = false;
      pageObj.setState({ isLoading: false });
    })
    .catch((error) => {
      if (error?.response?.status === 504) {
        setTimeout(() => retryReportCall(fileId), 5000);
      } else {
        reportProcess = false;
        pageObj.setState({ isLoading: false });
        pageObj.props.displayError(0, "", error);
      }
    });
}

const userSettings = {
  setLocalZone: (date) => setLocalZone(date),
  cancelReport: () => cancelReport(),
  encrypt: (text) => encrypt(text),
  downloadReport: (props, url, reportData) =>
    downloadReport(props, url, reportData),
  API_URL_HOST: config.API_URL_HOST,
  CLASSIC_URL: config.CLASSIC_URL_HOST,
  API_TOKEN: "",
  mainRecordStatus: 0,
  login_url: config.REACT_URL_LOGIN
};

function redirectToLogin() {
  window.location.replace(userSettings.login_url);
}

function isEmpty(str) {
  return (!str || str.length === 0);
}

function redirectWithToken(client, token) {
  let url = `${window.location.protocol}//${window.location.host}/#/dashboard?token=${encrypt(token)}&tid=${encrypt(client)}&loginPage=${encrypt(userSettings.login_url)}`;
  window.location.replace(url);
  window.location.reload();
}

function AppComponent(loginURL, token, client, qs, userSettings) {
  // let qs = top.location.hash;
  // let iPos = qs.indexOf("?");
  // if (iPos >= 0) qs = qs.substring(iPos + 1);
  // let client = decrypt(queryString.parse(qs).tid);
  // let token = decrypt(queryString.parse(qs).token);
  // let loginURL = queryString.parse(qs).loginPage;
  if (loginURL !== undefined) {
    loginURL = decrypt(loginURL);
  } else if (top.location.hostname.indexOf("localhost") != -1) {
    //loginURL = "http://localhost/DTS/FormsLogin.asp?";
    // loginURL = "http://localhost.ms.com:8088/";
  } else {
    //loginURL = "/DTS/FormsLogin.asp?";
    // loginURL = "/";
  }
  let actionC = loginURL.indexOf("action=");
  if (actionC != -1) {
    loginURL = loginURL.substring(0, actionC);
  }
  let lastC = loginURL.substring(loginURL.length - 1);
  if (loginURL.indexOf("?") != -1 && lastC != "&") {
    loginURL += "&";
  } else if (lastC == "\\" || lastC == "/") {
    loginURL += "?&";
  } else if (lastC != "&") {
    loginURL += "\\?&";
  }
  if (!isEmpty(loginURL))
    userSettings.login_url = loginURL;
  userSettings.API_TOKEN = "&token=" + token;
  let menu = queryString.parse(qs).menu;
  let subMenu = queryString.parse(qs).subMenu;
  let extraParameter = queryString.parse(qs).extraParameter;
  return (
    // <React.StrictMode>
    <AppContext.Provider value={userSettings}>
      {client == -10 ? <StandardTheme /> : <MSTheme />}
      <BrowserRouter>
        <Routes>
          {/* <Route path="/React/" element={<Full />} />
        <Route path="/dashboard" element={<Full />} /> */}
          {/* <Route path="/404" element={<Page404 />} /> */}
          <Route path="/500" element={<Page500 />} />
          <Route
            path="*"
            element={
              <ErrorBoundary rootError={true}>
                <Full
                  token={token}
                  menu={menu}
                  subMenu={subMenu}
                  extraParameter={extraParameter}
                />
              </ErrorBoundary>
            }
          />
          {/* <Route path="*" element={<Navigate to={"/dashboard"} replace />} /> */}
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
    //</React.StrictMode>
  );
}

function App() {
  let qs = top.location.hash;
  let iPos = qs.indexOf("?");
  if (iPos >= 0) qs = qs.substring(iPos + 1);
  let client = '';
  let token = '';
  let loginURL = '';
  try {
    if (!isEmpty(qs)) {
      client = decrypt(queryString.parse(qs).tid);
      token = decrypt(queryString.parse(qs).token);
      loginURL = queryString.parse(qs).loginPage;
    }
  } catch (error) {

  }
  if (isEmpty(client) || isEmpty(token)) {
    try {
      axios.get(userSettings.API_URL_HOST +
        DEFAULT_QUERY_COOKIE_TOKEN)
        .then((data) => {
          client = `${data.data.ClientId}`;
          token = `${data.data.TempSessionId}.${data.data.UserId}`;
          redirectWithToken(client, token);
        }).catch((error) => {
          redirectToLogin();
        });
    } catch (error) {
      redirectToLogin();
    }
  } else {
    return AppComponent(loginURL, token, client, qs, userSettings);
  }
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(App());
